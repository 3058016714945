import React, { memo, useCallback, useMemo } from "react";
import { Popover, Table } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../store";
import { useMsal } from "@azure/msal-react";
import { ICondition, IPolicy } from "../../pages/AddPolicy/types";
import { deletePolicy } from "../../store/Policies/actions";
import { conditionColumns, tableColumns } from "../../const";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import Button from "../../common/Button";
import ListPopup from "../../common/ListPopup";
import Operator from "../../common/Operator";

import "./style.scss";

type IProps = {
  policies: IPolicy[];
};

const PolicyTable = ({ policies }: IProps) => {
  const { tenantId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const msalInstance = useMsal();

  const handleDeletePolicy = useCallback(
    (Id: string) => {
      dispatch(deletePolicy({ tenantId, Id, msalInstance }));
    },
    [dispatch, msalInstance],
  );

  const handleSetEditablePolicyId = (Id: string) =>
    navigate(`editPolicy/${Id}`);

  const content = (id: string) => (
    <div className="popup">
      <div className="popup-question">
        <ExclamationCircleOutlined />
        <span>Are you sure?</span>
      </div>
      <div className="popup-buttons">
        <Button type="default" size="small" className="popup-btn">
          No
        </Button>
        <Button
          type="primary"
          onClick={() => handleDeletePolicy(id)}
          size="small"
        >
          Yes
        </Button>
      </div>
    </div>
  );

  const getTableViewData = (policy: IPolicy) => {
    return policy.Statement.map((item, index) => ({
      key: 0,
      policyId: <>{policy.Id}</>,
      statementNumber: <p className="pt-statement-number">{index + 1}</p>,
      effect: (
        <p
          className={`pt-statement-effect ${
            item.Effect === "Allow" ? "effect-allow" : "effect-deny"
          }`}
        >
          {item.Effect}
        </p>
      ),
      actions: <ListPopup list={item.Action} />,
      resource: <ListPopup list={item.Resource} />,
      conditions: item.Condition,
      buttons: (
        <div>
          <Button
            type="link"
            onClick={() => handleSetEditablePolicyId(policy.Id)}
          >
            Edit
          </Button>
          <Popover content={() => content(policy.Id)} trigger="click">
            <Button type="link">Delete</Button>
          </Popover>
        </div>
      ),
    }));
  };

  const handlePolicies = useMemo(
    () =>
      policies.map(item => {
        return getTableViewData(item);
      }),
    [],
  );

  const addKeys = useMemo(() => {
    const flatten = handlePolicies.flat();

    flatten.filter((item, index) => {
      item.key = index;
    });

    return flatten;
  }, []);

  const dropdownTable = useCallback((conditions: ICondition[]) => {
    const getCondition = conditions.map((item, index) => ({
      conditionNumber: <p className="pt-statement-number">{index + 1}</p>,
      subject: item.Subject,
      operator: <Operator value={item.Operator} />,
      value: item.Value,
      details: (
        <div>
          <div>{`${item.Format}${item.IgnoreCase ? "IgnoreCase" : ""}${
            item.IfExists ? "IfExists" : ""
          }`}</div>
          <div>{item.ApplyTo}</div>
        </div>
      ),
    }));

    return (
      <>
        <Table
          className="condition-table"
          columns={conditionColumns}
          dataSource={getCondition}
          pagination={false}
        />
      </>
    );
  }, []);

  return (
    <>
      <Table
        className="policy-table"
        style={{ margin: 24 }}
        columns={tableColumns}
        pagination={false}
        expandable={{
          expandedRowRender: record => dropdownTable(record.conditions),
        }}
        dataSource={addKeys}
      />
    </>
  );
};

export default memo(PolicyTable);
