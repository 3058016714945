import axios from "axios";

export const url =
  process.env.REACT_APP_POLICY_SERVER_URL || "http://localhost:3000/api/v1/";

const $host = axios.create({
  baseURL: url,
});

export { $host };
