import { useMsal } from "@azure/msal-react";
import { ChangeEvent, memo, useCallback, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { url } from "../../http";
import useFetch from "../../hooks/useFetch";
import { Route } from "antd/lib/breadcrumb/Breadcrumb";
import { Card, Descriptions, Input, message, PageHeader } from "antd";
import { tenantBreadcrumbs } from "../../const";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { deleteTenantRequest, updateTenant } from "../../http/userApi";

import Button from "../../common/Button";
import Spinner from "../../common/Spinner";

import "./style.scss";

const Tenant = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const msalInstance = useMsal();
  const [tenant, setTenant] = useState<{
    EntityName: string;
    LastModified: string;
  }>({
    EntityName: "",
    LastModified: "",
  });
  const [loading, setLoading] = useState(false);
  const [isEdit, setEdit] = useState(false);

  const { data, isLoading } = useFetch<{
    EntityName: string;
    LastModified: string;
  }>(`${url}/v1/tenants/${id}`, msalInstance);

  useEffect(() => {
    if (data) {
      setTenant(data);
    }
  }, [data]);

  const switchEdit = () => setEdit(!isEdit);

  const deleteTenant = useCallback(async () => {
    setLoading(true);

    if (id) {
      const result: {
        data: string | null;
        error: null | string;
      } = await deleteTenantRequest(id, msalInstance);

      if (result.data) {
        navigate("/");
        message.success({
          content: result.data,
          duration: 2,
        });
      }

      if (result.error) {
        message.error({
          content: "Something wrong",
          duration: 2,
        });
      }
    }

    setLoading(false);
  }, [id, navigate]);

  const switchToTenantsList = () => navigate("/tenants");

  const itemRender = (route: Route) => {
    if (route.path === "/:id") {
      return (
        <div className="tenant-name-breadcrumb" onClick={switchToTenantsList}>
          {data?.EntityName}
        </div>
      );
    } else {
      return <Link to={route.path}>{route.breadcrumbName}</Link>;
    }
  };

  const saveHandler = useCallback(async () => {
    try {
      setLoading(true);

      const newData = {
        name: tenant.EntityName,
        description: "",
        internal_policies: [
          {
            Statement: [
              {
                Sid: "OwnerAccess",
                Effect: "Allow",
                Action: [
                  "manager:Utilize",
                  "manager:Read",
                  "manager:Update",
                  "manager:Delete",
                ],
                Resource: `manager:object:${tenant.EntityName}`,
                Condition: {
                  StringEquals: {
                    "user:email": msalInstance.accounts[0].username || "",
                  },
                },
              },
            ],
          },
        ],
        addons: {},
        config: {
          TARGET_ROLE_ARN:
            "arn:aws:iam::473416480675:role/oauth/oauth-prod-iam-authz-mt-access-role",
          AWS_REGION: "eu-central-1",
          AWS_ACCOUNT_ID: 473416480675,
        },
      };

      const result: {
        data: string | null;
        error: null | string;
      } = await updateTenant(newData, msalInstance);

      if (result?.data) {
        message.success({
          content: result.data,
          duration: 3,
        });
      }

      if (!result.data) {
        if (data) {
          setTenant(data);
        }

        message.error({
          content: "Something wrong",
          duration: 3,
        });
      }
    } finally {
      setLoading(false);
      setEdit(false);
    }
  }, []);

  const handleEditTenantID = (e: ChangeEvent<HTMLInputElement>) => {
    setTenant({
      ...tenant,
      EntityName: e.target.value,
    });
  };

  const addButtons = [
    <Button
      icon={<DeleteOutlined />}
      loading={loading}
      onClick={deleteTenant}
      size="large"
    >
      Delete tenant
    </Button>,
  ];

  return (
    <div className="tenant">
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <PageHeader
            title={`Tenant ID: ${id}`}
            className="breadcrumbs"
            breadcrumb={{
              routes: tenantBreadcrumbs,
              itemRender,
            }}
            extra={addButtons}
          />
          <Card
            title="General"
            className="tenant-general-card"
            extra={
              !isEdit ? (
                <Button
                  onClick={switchEdit}
                  icon={<EditOutlined />}
                  size="large"
                >
                  Edit
                </Button>
              ) : (
                <>
                  <Button
                    size="large"
                    onClick={switchEdit}
                    className="cancel-btn"
                  >
                    Cancel
                  </Button>
                  <Button
                    size="large"
                    type="primary"
                    onClick={saveHandler}
                    loading={loading}
                  >
                    Save
                  </Button>
                </>
              )
            }
          >
            <Descriptions>
              <Descriptions.Item label="Tenant ID" className="tenant-descr">
                {!isEdit ? (
                  tenant.EntityName
                ) : (
                  <Input
                    value={tenant.EntityName}
                    onChange={handleEditTenantID}
                  />
                )}
              </Descriptions.Item>
            </Descriptions>
          </Card>
        </>
      )}
    </div>
  );
};

export default memo(Tenant);
