import { useEffect, memo, useState, useMemo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMsal } from "@azure/msal-react";
import { Empty, Pagination, Modal, Space, Spin } from "antd";
import { SegmentedValue } from "antd/lib/segmented";
import { loadPolicies } from "../../store/Policies/actions";
import { AppDispatch } from "../../store";
import { useParams } from "react-router-dom";
import {
  arePoliciesLoaded,
  getAllPolicies,
  getNotification,
} from "../../store/Policies/selectors";
import { deleteNotification } from "../../store/Policies/slice";
import ListPageHeader from "../../components/ListPageHeader";
import PolicyCard from "../../components/PolicyCard";
import PolicyTable from "../../components/PolicyTable";
import { IPolicy } from "../AddPolicy/types";

import "./style.scss";

const Policies = () => {
  const { tenantId } = useParams();
  const msalInstance = useMsal();
  const dispatch = useDispatch<AppDispatch>();
  const policies = useSelector(getAllPolicies);
  const isLoaded = useSelector(arePoliciesLoaded);
  const notification = useSelector(getNotification);
  const [effect, setEffect] = useState("All");
  const [sort, setSort] = useState("A-Z");
  const [segment, setSegment] = useState<SegmentedValue>("List");

  useEffect(() => {
    const secondsToGo = 5;
    if (notification) {
      const modal = Modal[notification.mode]({
        title: notification.title,
        content: notification.subTitle,
        className: "notification-wrapper",
        mask: false,
        style: {
          top: 64,
          right: 24,
          position: "absolute",
        },
        okButtonProps: {
          style: {
            display: "none",
          },
        },
        maskClosable: true,
        afterClose() {
          dispatch(deleteNotification());
          modal.destroy();
        },
      });
      setTimeout(() => {
        dispatch(deleteNotification());
        modal.destroy();
      }, secondsToGo * 1000);
    }
  }, [notification, dispatch]);

  const [searchItems, setSearchItems] = useState<string>("");
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage] = useState(5);

  const searchFiltered = useMemo(() => {
    return policies.reduce((acc: IPolicy[], item: IPolicy) => {
      const filteredItem = {
        ...item,
        Statement: item.Statement.filter(statement =>
          searchItems
            ? (statement.Action.find(subItem =>
                subItem.toLowerCase().includes(searchItems.toLowerCase()),
              ) ||
                item.Id.toLowerCase().includes(searchItems.toLowerCase())) &&
              (statement.Effect === effect || effect === "All")
            : statement.Effect === effect || effect === "All",
        ),
      };
      if (filteredItem.Statement.length) {
        acc.push(filteredItem);
      }

      return acc.sort((a, b) =>
        sort === "A-Z" ? a.Id.localeCompare(b.Id) : b.Id.localeCompare(a.Id),
      );
    }, []);
  }, [policies, searchItems, effect, sort]);

  useEffect(() => {
    searchFiltered.length < perPage * currentPage && setCurrentPage(1);
  }, [searchFiltered, perPage, currentPage]);

  const indexLastPolicy = currentPage * perPage;
  const indexFirstPolicy = indexLastPolicy - perPage;
  const currentPolicies = searchFiltered.slice(
    indexFirstPolicy,
    indexLastPolicy,
  );

  const handlePagination = useCallback((page: number) => {
    setCurrentPage(page);
  }, []);

  useEffect(() => {
    if (!isLoaded && !policies.length) {
      dispatch(loadPolicies({ tenantId, msalInstance }));
    }
  }, [dispatch, isLoaded, policies]);

  const handleChangeEffect = useCallback(
    (value: string) => setEffect(value),
    [],
  );

  const handleChangeSort = useCallback((value: string) => setSort(value), []);

  return (
    <div className="policies">
      <ListPageHeader
        tenantId={tenantId}
        setSearchItems={setSearchItems}
        effect={effect}
        setEffect={handleChangeEffect}
        sort={sort}
        setSort={handleChangeSort}
        segment={segment}
        setSegment={setSegment}
      />
      {isLoaded ? (
        currentPolicies.length ? (
          <>
            {segment === "List" ? (
              currentPolicies.map(item => {
                return <PolicyCard policy={item} key={item.Id} />;
              })
            ) : (
              <PolicyTable policies={currentPolicies} />
            )}
            <Pagination
              current={currentPage}
              showSizeChanger
              pageSize={5}
              defaultCurrent={1}
              total={searchFiltered.length}
              onChange={handlePagination}
            />
          </>
        ) : (
          <div className="empty-wrapper">
            <Empty description="No policies yet" />
          </div>
        )
      ) : (
        <div className="spinner-wrapper">
          <Space size="middle">
            <Spin size="large" />
          </Space>
        </div>
      )}
    </div>
  );
};

export default memo(Policies);
