import { IMsalContext } from "@azure/msal-react";
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios, { AxiosResponse } from "axios";
import { getAccessToken } from "../../config/token";
import { getHeaders } from "../../helpers";
import { IPolicyEntity, IStatementEntity } from "./types";

// Actions to work with policies
export const loadPolicies = createAsyncThunk(
  "policies/loadPolicies",
  async ({
    tenantId,
    msalInstance,
  }: {
    tenantId: string | undefined;
    msalInstance: IMsalContext;
  }) => {
    const token = await getAccessToken(msalInstance);

    const policies: AxiosResponse<{
      data: {
        Policies: Array<IPolicyEntity>;
      };
    }> = await axios.get(
      `${process.env.REACT_APP_POLICY_SERVER_URL}${tenantId}/policies/`,
      {
        headers: getHeaders(token),
      },
    );

    return policies.data.data;
  },
);

export const deletePolicy = createAsyncThunk(
  "policies/deletePolicy",
  async ({
    tenantId,
    Id,
    msalInstance,
  }: {
    tenantId: string | undefined;
    Id: string;
    msalInstance: IMsalContext;
  }) => {
    const token = await getAccessToken(msalInstance);

    await axios.delete(
      `${process.env.REACT_APP_POLICY_SERVER_URL}${tenantId}/policies/${Id}`,
      {
        headers: getHeaders(token),
      },
    );

    return Id;
  },
);

export const createPolicy = createAsyncThunk(
  "policies/createPolicy",
  async (
    {
      PolicyId,
      tenantId,
      Statement,
      msalInstance,
    }: {
      tenantId: string | undefined;
      PolicyId: string;
      Statement: IStatementEntity[];
      msalInstance: IMsalContext;
    },
    { rejectWithValue },
  ) => {
    const token = await getAccessToken(msalInstance);

    try {
      const policies: AxiosResponse<{
        data: {
          Policies: Array<IPolicyEntity>;
        };
      }> = await axios.post(
        `${process.env.REACT_APP_POLICY_SERVER_URL}${tenantId}/policies/${PolicyId}`,
        JSON.stringify({
          policy_text: JSON.stringify({
            Statement,
          }),
        }),
        {
          headers: getHeaders(token),
        },
      );

      return policies.data.data;
    } catch (err) {
      if (!navigator.onLine) {
        return rejectWithValue({
          title: "Problem connecting",
          text: "Check your internet connection and try again.",
          connection: true,
        });
      } else if (axios.isAxiosError(err) && err.response && err.response.data) {
        return rejectWithValue({
          title: "Saving could not be completed",
          text:
            (err.response.data as { detail: { Error: string } }).detail.Error ||
            "An unknown error occured. Please wait a few minutes and then try again or contact Administrator.",
        });
      }
    }
  },
);

export const updatePolicy = createAsyncThunk(
  "policies/updatePolicy",
  async (
    {
      tenantId,
      PolicyId,
      Statement,
      msalInstance,
    }: {
      tenantId: string | undefined;
      PolicyId: string;
      Statement: IStatementEntity[];
      msalInstance: IMsalContext;
    },
    { rejectWithValue },
  ) => {
    const token = await getAccessToken(msalInstance);

    const headers = {
      "x-api-key": process.env.REACT_APP_POLICY_SERVER_API_KEY,
      Authorization: token
        ? `Bearer ${token}`
        : process.env.REACT_APP_AUTHORIZATION,
      "Content-Type": "application/json",
    };

    try {
      const policies: AxiosResponse<{
        data: {
          Policies: Array<IPolicyEntity>;
        };
      }> = await axios.put(
        `${process.env.REACT_APP_POLICY_SERVER_URL}${tenantId}/policies/${PolicyId}`,
        JSON.stringify({
          policy_text: JSON.stringify({
            Statement,
          }),
        }),
        {
          headers,
        },
      );

      return policies.data.data;
    } catch (err) {
      if (!navigator.onLine) {
        return rejectWithValue({
          title: "Problem connecting",
          text: "Check your internet connection and try again.",
        });
      } else if (axios.isAxiosError(err) && err.response && err.response.data) {
        return rejectWithValue({
          title: "Saving could not be completed",
          text:
            (err.response.data as { detail: { Error: string } }).detail.Error ||
            "An unknown error occured. Please wait a few minutes and then try again or contact Administrator.",
        });
      }
    }
  },
);
