import { useNavigate, useParams } from "react-router-dom";
import { memo, useCallback, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, message, Popover, Segmented } from "antd";
import { useMsal } from "@azure/msal-react";

import {
  CopyOutlined,
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { SegmentedValue } from "antd/lib/segmented";

import { AppDispatch } from "../../store";
import { deletePolicy } from "../../store/Policies/actions";
import { IPolicy } from "../../pages/AddPolicy/types";
import PolicyStatement from "../PolicyStatement";
import Button from "../../common/Button";
import Editor from "../Editor";
import { ApplicationState } from "../../store/types";
import { getRawPolicyById } from "../../store/Policies/selectors";

import "./style.scss";

type IProps = {
  policy: IPolicy;
};

const PolicyCard = ({ policy }: IProps) => {
  const { tenantId } = useParams();
  const navigate = useNavigate();
  const msalInstance = useMsal();
  const { Statement, Id } = policy;
  const policyById = useSelector((state: ApplicationState) =>
    getRawPolicyById(state, Id),
  );
  const dispatch = useDispatch<AppDispatch>();
  const [visible, setVisible] = useState(false);
  const [segment, setSegment] = useState<SegmentedValue>("Advanced mode");

  const changeSegment = (value: SegmentedValue) => setSegment(value);

  const handleDeletePolicy = useCallback(() => {
    dispatch(deletePolicy({ tenantId, Id, msalInstance }));
  }, [dispatch, Id, msalInstance]);

  const handleVisibleChange = (newVisible: boolean) => {
    setVisible(newVisible);
  };

  const hidePopover = useCallback(() => {
    setVisible(false);
  }, []);

  const generatedBody = useMemo(
    () =>
      segment === "Advanced mode" ? (
        Statement.map(item => (
          <PolicyStatement statement={item} key={item.Id} />
        ))
      ) : (
        <Editor isPreview json={policyById} />
      ),
    [Statement, policy, segment, policyById],
  );

  const content = (
    <div className="popup">
      <div className="popup-question">
        <ExclamationCircleOutlined />
        <span>Are you sure you want to delete this policy?</span>
      </div>
      <div className="popup-buttons">
        <Button
          type="default"
          size="small"
          className="popup-btn"
          onClick={hidePopover}
        >
          No
        </Button>
        <Button type="primary" size="small" onClick={handleDeletePolicy}>
          Yes
        </Button>
      </div>
    </div>
  );

  const handleSetEditablePolicyId = () => navigate(`${policy.Id}`);

  const handleCopyJSON = () => {
    navigator.clipboard.writeText(JSON.stringify(policy));
    message.success({
      content: "JSON is copied",
      duration: 1,
    });
  };

  return (
    <>
      <Card
        title={`Policy ID: ${policy.Id}`}
        bodyStyle={{
          padding: "0",
          background: "#FAFAFA",
        }}
        headStyle={{
          boxShadow: "inset 0px -1px 0px #F0F0F0",
        }}
        extra={
          <div>
            <Popover
              content={content}
              trigger="click"
              visible={visible}
              onVisibleChange={handleVisibleChange}
            >
              <Button type="default" size="middle" icon={<DeleteOutlined />}>
                Delete
              </Button>
            </Popover>
            <Button
              size="middle"
              icon={<CopyOutlined />}
              onClick={handleCopyJSON}
              className="copy-button"
            >
              Copy JSON
            </Button>
            <Button
              type="secondary"
              size="middle"
              icon={<EditOutlined />}
              onClick={handleSetEditablePolicyId}
            >
              Edit
            </Button>
          </div>
        }
        className="policy-card"
      >
        <div className="segmented-body">
          <Segmented
            options={["Advanced mode", "JSON view"]}
            value={segment}
            onChange={changeSegment}
            size="large"
          />
        </div>
        {generatedBody}
      </Card>
    </>
  );
};

export default memo(PolicyCard);
