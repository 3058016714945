import { memo, useCallback, useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";
import { v4 as uuidv4 } from "uuid";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Modal, PageHeader, Popover, Segmented } from "antd";
import { DeleteOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { SegmentedValue } from "antd/lib/segmented";
import { Route } from "antd/lib/breadcrumb/Breadcrumb";
import { ParseError, SchemaValidationError } from "jsoneditor";

import DiscardModal from "../../components/DiscardModal";
import Editor from "../../components/Editor";
import PolicyForm from "../../components/PolicyForm";
import { loadHints } from "../../store/Hints/actions";
import { AppDispatch } from "../../store";
import Button from "../../common/Button";
import {
  addPolicyBreadcrumbs,
  ConditionEntity,
  editPolicyBreadcrumbs,
  NewJsonPolicy,
  StatementEntity,
} from "../../const";
import { areHintsLoaded } from "../../store/Hints/selectors";
import {
  createPolicy,
  deletePolicy,
  updatePolicy,
} from "../../store/Policies/actions";
import {
  getNotification,
  getRawPolicyById,
} from "../../store/Policies/selectors";

import {
  generateConditions,
  generateStatementsFromEntity,
  generateStatementsToEntity,
  getCondition,
} from "../../helpers";

import { IPolicyEntity, IStatementEntity } from "../../store/Policies/types";
import { IPolicy, IStatement } from "./types";
import { ApplicationState } from "../../store/types";

import "./style.scss";
import { deleteNotification } from "../../store/Policies/slice";

type IProps = {
  policy?: IPolicy;
};

const AddPolicy = ({ policy }: IProps) => {
  const { tenantId } = useParams();
  const msalInstance = useMsal();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const [visible, setVisible] = useState(false);
  const notification = useSelector(getNotification);

  const [policyId, setPolicyId] = useState(policy?.Id || "");
  const [validationError, setValidationError] = useState<string>("");
  const [hasValidationErrors, setErrors] = useState<
    readonly (SchemaValidationError | ParseError)[]
  >([]);
  const [hasStatementError, setStatementError] = useState<boolean>(false);
  const [statements, setStatements] = useState<IStatement[]>(
    policy?.Statement || [StatementEntity],
  );

  const policyById = useSelector((state: ApplicationState) =>
    getRawPolicyById(state, policyId),
  );

  const [jsonPolicy, setJsonPolicy] = useState(policyById || NewJsonPolicy);

  const [segment, setSegment] = useState<SegmentedValue>("Advanced mode");

  const [isSaveClicked, setSaveClicked] = useState(false);

  const isHintsDataLoaded = useSelector(areHintsLoaded);

  useEffect(() => {
    if (notification && notification.mode === "error") {
      const modal = Modal[notification.mode]({
        title: notification.title,
        content: notification.subTitle,
        className: "notification-wrapper",
        mask: false,
        style: {
          top: 64,
          right: 24,
          position: "absolute",
        },
        okText: "Retry",
        okButtonProps: {
          style: {
            display: `${notification.connection ? "" : "none"}`,
          },
        },
        cancelText: "Cancel",
        onCancel: () => navigate(`/${tenantId}/policies`),
        closable: true,
        maskClosable: false,
        afterClose() {
          dispatch(deleteNotification());
          modal.destroy();
        },
      });
    } else if (notification && notification.mode === "success") {
      navigate(`/${tenantId}/policies`);
    }
  }, [notification, dispatch]);

  useEffect(() => {
    !isHintsDataLoaded && dispatch(loadHints(msalInstance));
  }, [dispatch, isHintsDataLoaded, msalInstance]);

  useEffect(() => {
    !hasValidationErrors.length && validationError && setValidationError("");
  }, [hasValidationErrors, validationError]);

  const handleChangeStatement = useCallback(
    (id: string, field: string, value: string | Array<string>) => {
      setStatements(
        statements.map(item =>
          item.Id === id ? { ...item, [field]: value } : item,
        ),
      );
    },
    [statements],
  );

  const handleChangeCondition = useCallback(
    (
      statementId: string,
      conditionId: string,
      field: string,
      value?: string | Array<string>,
    ) =>
      setStatements(
        statements.map(item =>
          item.Id === statementId
            ? {
                ...item,
                Condition: generateConditions(
                  item.Condition,
                  conditionId,
                  field,
                  value,
                ),
              }
            : item,
        ),
      ),
    [statements],
  );

  const handleChangeCheckBox = useCallback(
    (statementId: string, conditionId: string, field: string, value: boolean) =>
      setStatements(
        statements.map(item =>
          item.Id === statementId
            ? {
                ...item,
                Condition: item.Condition?.map(subItem =>
                  subItem.Id === conditionId
                    ? {
                        ...subItem,
                        [field]: value,
                      }
                    : subItem,
                ),
              }
            : item,
        ),
      ),
    [statements],
  );

  const handleAddCondition = useCallback(
    (id: string) =>
      setStatements(
        statements.map((item: IStatement) =>
          item.Id === id
            ? {
                ...item,
                Condition: [
                  ...item.Condition,
                  { ...ConditionEntity, Id: uuidv4() },
                ],
              }
            : item,
        ),
      ),
    [statements],
  );

  const handleDuplicateCondition = useCallback(
    (statementId: string, conditionId: string) => {
      const condition = getCondition(statementId, conditionId, statements);

      const newStatement = statements.map((item: IStatement) =>
        item.Id === statementId
          ? {
              ...item,
              Condition: [...item.Condition, { ...condition, Id: uuidv4() }],
            }
          : item,
      );

      return setStatements(newStatement);
    },
    [statements],
  );

  const handleDeleteCondition = useCallback(
    (statementId: string, conditionId: string) =>
      setStatements(
        statements.map(item =>
          item.Id === statementId
            ? {
                ...item,
                Condition: item.Condition.filter(
                  condition => condition.Id !== conditionId,
                ),
              }
            : item,
        ),
      ),
    [statements],
  );

  const handleVisibleChange = (newVisible: boolean) => {
    setVisible(newVisible);
  };

  const handleDeleteStatement = useCallback(
    (statementId: string) =>
      setStatements(statements.filter(item => item.Id !== statementId)),
    [statements],
  );

  const handleAddStatment = useCallback(
    () => setStatements([...statements, { ...StatementEntity, Id: uuidv4() }]),
    [statements],
  );

  const saveHandler = useCallback(() => {
    if (hasValidationErrors.length) {
      setValidationError(
        "The policy cannot be saved until JSON contains errors.",
      );
      return;
    }
    if (!statements.length) {
      setStatementError(true);
      return;
    }
    setSaveClicked(true);
    const Statement: IStatementEntity[] =
      segment === "Advanced mode"
        ? generateStatementsToEntity(statements)
        : jsonPolicy.Statement;

    const action = policy ? updatePolicy : createPolicy;
    dispatch(action({ tenantId, PolicyId: policyId, Statement, msalInstance }));
  }, [
    statements,
    policy,
    policyId,
    msalInstance,
    navigate,
    dispatch,
    hasValidationErrors,
    segment,
  ]);

  const hidePopover = useCallback(() => {
    setVisible(false);
  }, []);

  const handleDeletePolicy = useCallback(() => {
    dispatch(deletePolicy({ tenantId, Id: policyId, msalInstance })).then(
      () => {
        navigate(`/${tenantId}/policies`);
      },
    );
  }, [dispatch, policyId, navigate, msalInstance]);

  const content = (
    <div className="popup">
      <div className="popup-question">
        <ExclamationCircleOutlined />
        <span>Are you sure you want to delete this policy?</span>
      </div>
      <div className="popup-buttons">
        <Button
          type="default"
          size="small"
          className="popup-btn"
          onClick={hidePopover}
        >
          No
        </Button>
        <Button type="primary" size="small" onClick={handleDeletePolicy}>
          Yes
        </Button>
      </div>
    </div>
  );

  const addButtons = [
    <Link to="/" key="1">
      <Button>Cancel</Button>
    </Link>,
    <Button form="policyForm" key="submit" htmlType="submit" type="primary">
      Save
    </Button>,
  ];

  const editButtons = [
    <Popover
      content={content}
      trigger="click"
      visible={visible}
      onVisibleChange={handleVisibleChange}
    >
      <Button type="link" size="middle" icon={<DeleteOutlined />}>
        Delete policy
      </Button>
    </Popover>,
    ...addButtons,
  ];

  const itemRender = (route: Route) => {
    if (route.path === "/addPolicy") {
      return <div>{route.breadcrumbName}</div>;
    } else {
      return <Link to={route.path}>{route.breadcrumbName}</Link>;
    }
  };

  const changeSegment = useCallback(
    (value: SegmentedValue) => {
      const cantSwitchErrors = hasValidationErrors.find(error => {
        const errorWithType = error as unknown as { keyword: string };
        return (
          errorWithType.keyword !== "required" &&
          errorWithType.keyword !== "minItems" &&
          errorWithType.keyword !== "minProperties"
        );
      });

      if (cantSwitchErrors) {
        return setValidationError(
          "JSON view cannot be switched while JSON contains errors.",
        );
      }
      if (value === "Advanced mode") {
        setStatements(generateStatementsFromEntity(jsonPolicy.Statement));
      } else {
        setJsonPolicy({
          Id: policyId,
          Statement: generateStatementsToEntity(statements),
        });
      }
      return setSegment(value);
    },
    [[hasValidationErrors, policyId, statements]],
  );

  const changeJSON = useCallback(
    (item: IPolicyEntity) => {
      setJsonPolicy(item);
      !policy && setPolicyId(item.Id);
    },
    [policy],
  );

  return (
    <div className="add-policy">
      <DiscardModal
        oldStatements={policy?.Statement || [StatementEntity]}
        changedStatements={statements}
        oldPolicyId={policy?.Id || ""}
        changedPolicyId={policyId}
        isSaveButtonClicked={isSaveClicked}
        json={jsonPolicy}
        oldJson={policyById || NewJsonPolicy}
      />
      <PageHeader
        title={`${policy ? `Policy ID: ${policy.Id}` : "Add policy"}`}
        className="breadcrumbs"
        breadcrumb={{
          routes: policy ? editPolicyBreadcrumbs : addPolicyBreadcrumbs,
          itemRender,
        }}
        extra={policy ? editButtons : addButtons}
      />
      <>
        <Segmented
          options={["Advanced mode", "JSON view"]}
          value={segment}
          onChange={changeSegment}
          size="large"
          className="tabs"
        />
        {segment === "JSON view" ? (
          <Editor
            onChangeJSON={changeJSON}
            json={jsonPolicy}
            oldJson={policyById || NewJsonPolicy}
            savePolicy={saveHandler}
            setErrors={setErrors}
            error={validationError}
            isAddPage={!policy}
          />
        ) : (
          <PolicyForm
            changeStatement={handleChangeStatement}
            changeCondition={handleChangeCondition}
            addCondition={handleAddCondition}
            deleteCondition={handleDeleteCondition}
            changeCheckBox={handleChangeCheckBox}
            deleteStatement={handleDeleteStatement}
            policy={policy}
            policyId={policyId}
            isEditPage={!!policy}
            changePolicyId={setPolicyId}
            savePolicy={saveHandler}
            addStatement={handleAddStatment}
            statements={statements}
            hasError={hasStatementError}
            duplicateCondition={handleDuplicateCondition}
          />
        )}
      </>
    </div>
  );
};

export default memo(AddPolicy);
