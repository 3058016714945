import { Option } from "./types";
import { v4 as uuidv4 } from "uuid";
import { Link } from "react-router-dom";
import { ColumnsType } from "antd/lib/table";

export const userGuide =
  "https://data-at-scale.docs.cloud.bayer.com/modules/policy-server/docs/user_guide/";
export const teamChannel =
  "https://teams.microsoft.com/l/channel/19%3a7CPIxhNYJQei-8x_wIxdh4MjaxWefdFSz5BSl2ksqhI1%40thread.tacv2/General?groupId=113a784c-eca0-4233-95d8-4add9ab5742a&tenantId=fcb2b37b-5da0-466b-9b83-0014b67a7c78";
export const mailTo = "mailto:jens.vetter@bayer.com";

export const policiesColumns = [
  {
    title: "Subject",
    dataIndex: "subject",
    key: "subject",
    width: "294px",
  },
  {
    title: "Operator",
    dataIndex: "operator",
    key: "operator",
    width: "120px",
  },
  {
    title: "Value",
    dataIndex: "value",
    key: "value",
  },
  {
    title: "Details",
    dataIndex: "details",
    key: "details",
    width: "180px",
  },
];

export const conditionColumns = [
  {
    title: "Condition No.",
    dataIndex: "conditionNumber",
    key: "conditionNumber",
    width: "187px",
  },
  {
    title: "Subject",
    dataIndex: "subject",
    key: "subject",
    width: "349px",
  },
  {
    title: "Operator",
    dataIndex: "operator",
    key: "operator",
    width: "156px",
  },
  {
    title: "Value",
    dataIndex: "value",
    key: "value",
    width: "359px",
  },
  {
    title: "Details",
    dataIndex: "details",
    key: "details",
    width: "359px",
  },
];

export const SubjectOptions: Array<Option> = [
  {
    id: 1,
    name: "environment",
    value: "env",
  },
  {
    id: 2,
    name: "request",
    value: "request",
  },
  {
    id: 3,
    name: "resource",
    value: "resource",
  },
  {
    id: 4,
    name: "user",
    value: "user",
  },
];

export const tableColumns = [
  {
    title: "Policy ID",
    dataIndex: "policyId",
    key: "policyId",
    width: "250px",
  },
  {
    title: "Statement No.",
    dataIndex: "statementNumber",
    key: "statementNumber",
    width: "134px",
  },
  {
    title: "Effects",
    dataIndex: "effect",
    key: "effect",
    width: "113px",
  },
  {
    title: "Action",
    dataIndex: "actions",
    key: "actions",
    width: "250px",
  },
  {
    title: "Resource",
    dataIndex: "resource",
    key: "resource",
    width: "250px",
  },
  {
    title: "Actions",
    dataIndex: "buttons",
    key: "buttons",
    width: "120px",
  },
];

export const OperatorOptions = (
  format?: string,
): Array<
  Option & {
    color: string;
    disabled: boolean;
  }
> => [
  {
    id: 1,
    name: "≤",
    value: "LessThanEquals",
    disabled: format === "String",
    color: "blue",
  },
  {
    id: 2,
    name: "<",
    value: "LessThan",
    disabled: format === "String",
    color: "magenta",
  },
  {
    id: 3,
    name: "=",
    value: "Equals",
    disabled: false,
    color: "purple",
  },
  {
    id: 4,
    name: "≠",
    value: "NotEquals",
    disabled: false,
    color: "orange",
  },
  {
    id: 5,
    name: ">",
    value: "GreaterThan",
    disabled: format === "String",
    color: "cyan",
  },
  {
    id: 6,
    name: "≥",
    value: "GreaterThanEquals",
    disabled: format === "String",
    color: "geekblue",
  },
  {
    id: 7,
    name: "Like",
    value: "Like",
    disabled: format === "Numeric",
    color: "green",
  },
  {
    id: 8,
    name: "Not like",
    value: "NotLike",
    disabled: format === "Numeric",
    color: "volcano",
  },
];

export const ApplyToOptions: Array<Option> = [
  {
    id: 1,
    name: "Default",
    value: "",
  },
  {
    id: 2,
    name: "for any value in request",
    value: "ForAnyValue",
  },
  {
    id: 3,
    name: "for all values in request",
    value: "ForAllValues",
  },
];

export const FormatOfAttributeOptions: Array<Option> = [
  {
    id: 1,
    name: "numeric",
    value: "Numeric",
  },
  {
    id: 2,
    name: "text",
    value: "String",
  },
];

export const addPolicyBreadcrumbs = [
  {
    path: "/",
    key: 1,
    breadcrumbName: "Policies list",
  },
  {
    path: "/addPolicy",
    key: 2,
    breadcrumbName: "Add policy",
  },
];

export const editPolicyBreadcrumbs = [
  {
    path: "/",
    key: 1,
    breadcrumbName: "Policies list",
  },
  {
    path: "/addPolicy",
    key: 2,
    breadcrumbName: "Edit policy",
  },
];

export const ConditionEntity = {
  Id: "0",
  ApplyTo: "ForAllValues",
  Attribute: "",
  Value: [],
  IfExists: false,
  IgnoreCase: false,
};

export const StatementEntity = {
  Id: uuidv4(),
  Resource: [],
  Action: [],
  Condition: [],
};

export const NewJsonPolicy = {
  Id: "",
  Statement: [
    {
      Id: uuidv4(),
      Resource: [],
      Action: [],
      Condition: {},
    },
  ],
};

const getConditionSchema = (subjectNames: Array<string>) => {
  let subjects = "";

  subjectNames.forEach((subject, index) => {
    if (index === 0) {
      subjects = subjects.concat(subject);
    } else {
      subjects = subjects.concat(`|${subject}`);
    }
  });

  return {
    type: "object",
    unevaluatedProperties: false,
    minProperties: 1,
    patternProperties: {
      [`^${subjects}:.*$`]: {
        type: "array",
        uniqueItems: true,
        minItems: 1,
        items: [{ type: "string" }],
      },
    },
    additionalProperties: false,
  };
};

export const EditorSchema = (subjectNames: Array<string>) => ({
  title: "Policy",
  type: "object",
  required: ["Id", "Statement"],
  additionalProperties: false,
  properties: {
    Id: {
      type: "string",
    },
    Statement: {
      type: "array",
      minItems: 1,
      items: [
        {
          required: ["Action", "Condition", "Effect", "Resource", "Id"],
          additionalProperties: false,
          type: "object",
          properties: {
            Action: {
              type: "array",
              uniqueItems: true,
              minItems: 1,
              items: [
                {
                  type: "string",
                  pattern: "^([a-z]{1,32}:[A-Za-z*]{1,64})",
                },
              ],
            },
            Id: {
              type: "string",
            },
            Effect: {
              type: "string",
              enum: ["Allow", "Deny"],
            },
            Resource: {
              type: "array",
              uniqueItems: true,
              minItems: 1,
              items: [
                {
                  type: "string",
                  pattern: "^([a-z]{1,32}:[A-Za-z0-9:\\*._-]{1,256}|\\*)$",
                },
              ],
            },
            Condition: {
              type: "object",
              additionalProperties: false,
              properties: {
                "ForAllValues:NumericEquals": getConditionSchema(subjectNames),
                "ForAllValues:NumericEqualsIfExists":
                  getConditionSchema(subjectNames),
                "ForAllValues:NumericGreaterThan":
                  getConditionSchema(subjectNames),
                "ForAllValues:NumericGreaterThanEquals":
                  getConditionSchema(subjectNames),
                "ForAllValues:NumericGreaterThanEqualsIfExists":
                  getConditionSchema(subjectNames),
                "ForAllValues:NumericGreaterThanIfExists":
                  getConditionSchema(subjectNames),
                "ForAllValues:NumericLessThan":
                  getConditionSchema(subjectNames),
                "ForAllValues:NumericLessThanEquals":
                  getConditionSchema(subjectNames),
                "ForAllValues:NumericLessThanEqualsIfExists":
                  getConditionSchema(subjectNames),
                "ForAllValues:NumericLessThanIfExists":
                  getConditionSchema(subjectNames),
                "ForAllValues:NumericNotEquals":
                  getConditionSchema(subjectNames),
                "ForAllValues:NumericNotEqualsIfExists":
                  getConditionSchema(subjectNames),
                "ForAllValues:StringEquals": getConditionSchema(subjectNames),
                "ForAllValues:StringEqualsIfExists":
                  getConditionSchema(subjectNames),
                "ForAllValues:StringEqualsIgnoreCase":
                  getConditionSchema(subjectNames),
                "ForAllValues:StringEqualsIgnoreCaseIfExists":
                  getConditionSchema(subjectNames),
                "ForAllValues:StringLike": getConditionSchema(subjectNames),
                "ForAllValues:StringLikeIfExists":
                  getConditionSchema(subjectNames),
                "ForAllValues:StringNotEquals":
                  getConditionSchema(subjectNames),
                "ForAllValues:StringNotEqualsIfExists":
                  getConditionSchema(subjectNames),
                "ForAllValues:StringNotEqualsIgnoreCase":
                  getConditionSchema(subjectNames),
                "ForAllValues:StringNotEqualsIgnoreCaseIfExists":
                  getConditionSchema(subjectNames),
                "ForAllValues:StringNotLike": getConditionSchema(subjectNames),
                "ForAllValues:StringNotLikeIfExists":
                  getConditionSchema(subjectNames),
                "ForAnyValue:NumericEquals": getConditionSchema(subjectNames),
                "ForAnyValue:NumericEqualsIfExists":
                  getConditionSchema(subjectNames),
                "ForAnyValue:NumericGreaterThan":
                  getConditionSchema(subjectNames),
                "ForAnyValue:NumericGreaterThanEquals":
                  getConditionSchema(subjectNames),
                "ForAnyValue:NumericGreaterThanEqualsIfExists":
                  getConditionSchema(subjectNames),
                "ForAnyValue:NumericGreaterThanIfExists":
                  getConditionSchema(subjectNames),
                "ForAnyValue:NumericLessThan": getConditionSchema(subjectNames),
                "ForAnyValue:NumericLessThanEquals":
                  getConditionSchema(subjectNames),
                "ForAnyValue:NumericLessThanEqualsIfExists":
                  getConditionSchema(subjectNames),
                "ForAnyValue:NumericLessThanIfExists":
                  getConditionSchema(subjectNames),
                "ForAnyValue:NumericNotEquals":
                  getConditionSchema(subjectNames),
                "ForAnyValue:NumericNotEqualsIfExists":
                  getConditionSchema(subjectNames),
                "ForAnyValue:StringEquals": getConditionSchema(subjectNames),
                "ForAnyValue:StringEqualsIfExists":
                  getConditionSchema(subjectNames),
                "ForAnyValue:StringEqualsIgnoreCase":
                  getConditionSchema(subjectNames),
                "ForAnyValue:StringEqualsIgnoreCaseIfExists":
                  getConditionSchema(subjectNames),
                "ForAnyValue:StringLike": getConditionSchema(subjectNames),
                "ForAnyValue:StringLikeIfExists":
                  getConditionSchema(subjectNames),
                "ForAnyValue:StringNotEquals": getConditionSchema(subjectNames),
                "ForAnyValue:StringNotEqualsIfExists":
                  getConditionSchema(subjectNames),
                "ForAnyValue:StringNotEqualsIgnoreCase":
                  getConditionSchema(subjectNames),
                "ForAnyValue:StringNotEqualsIgnoreCaseIfExists":
                  getConditionSchema(subjectNames),
                "ForAnyValue:StringNotLike": getConditionSchema(subjectNames),
                "ForAnyValue:StringNotLikeIfExists":
                  getConditionSchema(subjectNames),
              },
            },
          },
        },
      ],
    },
  },
});

interface ITenantsTableRow {
  name: string;
  lastUpdated: string;
}

export const TENANT_TABLE_COLUMNS: ColumnsType<ITenantsTableRow> = [
  {
    title: "Tenants",
    dataIndex: "name",
    key: "name",
    sorter: (a, b) => a.name.length - b.name.length,
    render: (value: string) => (
      <div className="name">
        <Link to={`/${value}/policies`}>{value}</Link>
      </div>
    ),
  },
  {
    title: "Last updated",
    dataIndex: "lastUpdated",
    key: "lastUpdated",
    width: "200px",
    render: (value: string) => <div>{value}</div>,
    sorter: (a, b) => a.lastUpdated.length - b.lastUpdated.length,
  },
];

export const addTenantBreadcrumbs = [
  {
    path: "/",
    key: 1,
    breadcrumbName: "Tenants list",
  },
  {
    path: "/addTenant",
    key: 2,
    breadcrumbName: "Add tenant",
  },
];

export const tenantBreadcrumbs = [
  {
    path: "/",
    key: 1,
    breadcrumbName: "Tenants list",
  },
  {
    path: "/:id",
    key: 2,
    breadcrumbName: "Tenant",
  },
];
