import { ChangeEvent, memo, useEffect, useState } from "react";
import {
  Form,
  message,
  Modal,
  PageHeader,
  notification as AntNotification,
  Card,
  Descriptions,
  Input,
} from "antd";
import { Route } from "antd/lib/breadcrumb/Breadcrumb";
import { Link, useNavigate } from "react-router-dom";

import { addTenantBreadcrumbs } from "../../const";
import { EModes, INotification } from "../Tenants/types";
import { createTenant } from "../../http/userApi";
import { useMsal } from "@azure/msal-react";

import Button from "../../common/Button";
import Spinner from "../../common/Spinner";

import "./style.scss";

const AddTenant = () => {
  const msalInstance = useMsal();
  const [data, setData] = useState<{
    tenantId: string;
  }>({
    tenantId: "",
  });
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(false);

  const [notification, setNotification] = useState<INotification | null>(null);

  useEffect(() => {
    if (notification && notification.mode === "error") {
      const modal = Modal[notification.mode]({
        title: notification.title,
        className: "notification-wrapper",
        mask: false,
        style: {
          top: 64,
          right: 24,
          position: "absolute",
        },
        okText: "Retry",
        okButtonProps: {
          style: {
            display: "none",
          },
        },
        cancelText: "Cancel",
        closable: true,
        maskClosable: false,
        afterClose() {
          setNotification(null);
          modal.destroy();
        },
      });
      setTimeout(() => {
        setNotification(null);
        modal.destroy();
      }, 5000);
    }
  }, [notification, setNotification, navigate]);

  const itemRender = (route: Route) => {
    if (route.path === "/addTenant") {
      return <div>{route.breadcrumbName}</div>;
    } else {
      return <Link to={route.path}>{route.breadcrumbName}</Link>;
    }
  };

  const handleChangeTenantId = (e: ChangeEvent<HTMLInputElement>) => {
    setData({
      tenantId: e.target.value,
    });
  };

  const handleSave = async () => {
    if (new RegExp(/[a-z]{4,20}/g).test(data.tenantId) === false) {
      AntNotification.error({
        message: "Error",
        description:
          "Tenant ID format is incorrect. Letters must be in lower case. Minimum number of characters is 4",
      });

      return;
    } else if (new RegExp(/\s+/g).test(data.tenantId)) {
      AntNotification.error({
        message: "Error",
        description:
          "Tenant ID format is incorrect. Should remove spaces from the field",
      });

      return;
    }

    try {
      console.log("-");
      setLoading(true);
      console.log(data);
      const newData = {
        name: data.tenantId,
        description: "",
        plugins: {
          SecretsIamSession: {
            enabled: true,
            pattern: "arn:aws:iam::*:role/*",
            SKIP_SESSION_TAGS: false,
            MAX_AD_GROUPS: 3,
          },
          SecretsIamUser: {
            enabled: true,
            pattern: "arn:aws:iam::*:user/*",
            IAM_GROUP_TARGET_PREFIX: "_dpadu-prod-",
            SKIP_USER_TAGS: false,
            IAM_USER_SESSION_LIFETIME: "1",
            SESSION_MODE: true,
            MAX_AD_GROUPS: 3,
          },
          AuthAllowAll: {
            enabled: true,
          },
          AuthPolicyEngine: {
            enabled: true,
            policies: [],
          },
          AuthUserAttrs: {
            enabled: true,
          },
        },
        internal_policies: [
          {
            Statement: [
              {
                Sid: "OwnerAccess",
                Effect: "Allow",
                Action: [
                  "manager:Utilize",
                  "manager:Read",
                  "manager:Update",
                  "manager:Delete",
                ],
                Resource: `manager:object:${data.tenantId}`,
                Condition: {
                  StringEquals: {
                    "user:email": msalInstance.accounts[0].username || "",
                  },
                },
              },
            ],
          },
        ],
        addons: {},
        config: {
          TARGET_ROLE_ARN:
            "arn:aws:iam::473416480675:role/oauth/oauth-prod-iam-authz-mt-access-role",
          AWS_REGION: "eu-central-1",
          AWS_ACCOUNT_ID: 473416480675,
        },
      };

      console.log(newData);
      console.log("=");

      const result: {
        data: string | null;
        error: null | string;
      } = await createTenant(newData, msalInstance);

      if (result?.data) {
        navigate("/" + data.tenantId + "/policies");
        message.success({
          content: result.data,
          duration: 3,
        });
      }

      if (result.error) {
        setNotification({
          mode: EModes.Error,
          title: result.error,
        });
      }
    } finally {
      setLoading(false);
      return;
    }
  };

  const addButtons = [
    <Link to="/" key="1">
      <Button>Cancel</Button>
    </Link>,
    <Button onClick={handleSave} key="submit" htmlType="submit" type="primary">
      Save
    </Button>,
  ];

  return (
    <div className="add-tenant">
      <PageHeader
        title="Add tenant"
        className="breadcrumbs"
        breadcrumb={{
          routes: addTenantBreadcrumbs,
          itemRender,
        }}
        extra={addButtons}
      />
      <div className="add-tenant-body">
        {isLoading ? (
          <Spinner />
        ) : (
          <Form
            name="basic"
            autoComplete="off"
            requiredMark={false}
            layout="vertical"
          >
            <Card title="General" className="tenant-general-card">
              <Descriptions>
                <Descriptions.Item label="Tenant ID" className="tenant-descr">
                  <Input
                    value={data.tenantId}
                    onChange={handleChangeTenantId}
                  />
                </Descriptions.Item>
              </Descriptions>
            </Card>
          </Form>
        )}
      </div>
    </div>
  );
};

export default memo(AddTenant);
