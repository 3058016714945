import { createAsyncThunk } from "@reduxjs/toolkit";
import { IMsalContext } from "@azure/msal-react";
import axios, { AxiosResponse } from "axios";
import { getAccessToken } from "../../config/token";

import { Hints } from "./types";
import { getHeaders } from "../../helpers";

// Action to get hins
export const loadHints = createAsyncThunk(
  "hints/loadHints",
  async (msalInstance: IMsalContext) => {
    const token = await getAccessToken(msalInstance);

    const hints: AxiosResponse<{
      data: {
        hints: Hints;
      };
    }> = await axios.get(
      `${process.env.REACT_APP_POLICY_SERVER_URL}default/complete_hints`,
      {
        headers: getHeaders(token),
      },
    );

    return hints.data;
  },
);
