import axios, { AxiosError } from "axios";
import { OperatorOptions } from "../const";
import { ICondition, IStatement } from "../pages/AddPolicy/types";
import { IStatementEntity } from "../store/Policies/types";

const isDevelopment = process.env.NODE_ENV === "development";

const getPath = (path: string) => {
  return `${
    isDevelopment
      ? process.env.REACT_APP_LOCAL_PORT
      : process.env.REACT_APP_POLICY_SERVER_URL
  }/${path}`;
};

// Request headers
const getHeaders = (token: string | void) => ({
  "x-api-key": process.env.REACT_APP_POLICY_SERVER_API_KEY,
  Authorization:
    !isDevelopment && token
      ? `Bearer ${token}`
      : process.env.REACT_APP_AUTHORIZATION,
  "Content-Type": "application/json",
});

const getTenantHeaders = (token: string | void) => ({
  "x-api-key": process.env.REACT_APP_POLICY_SERVER_API_KEY,
  Authorization:
    !isDevelopment && token
      ? `Bearer ${token}`
      : process.env.REACT_APP_AUTHORIZATION,
  "Content-Type": "application/json",
});

const getCondition = (
  statementId: string,
  conditionId: string,
  statements: IStatement[],
) => {
  return (
    statements
      .find((statement: IStatement) => statement.Id === statementId)
      ?.Condition.find(
        (condition: ICondition) => condition.Id === conditionId,
      ) || statements[0].Condition[0]
  );
};

const generateStatementsToEntity = (
  statements: IStatement[],
): IStatementEntity[] => {
  return statements.map(item => ({
    ...item,
    Condition: item.Condition.reduce(
      (
        acc: {
          [index: string]: {
            [index: string]: Array<string>;
          };
        },
        subItem,
      ) => {
        let key = "";
        if (subItem.ApplyTo && subItem.ApplyTo !== "Single value") {
          key = key.concat(`${subItem.ApplyTo}:`);
        }
        if (subItem.Format) {
          key = key.concat(subItem.Format);
        }
        if (subItem.Operator) {
          key = key.concat(subItem.Operator);
        }
        if (subItem.IgnoreCase) {
          key = key.concat("IgnoreCase");
        }
        if (subItem.IfExists) {
          key = key.concat("IfExists");
        }
        const subject = `${subItem.Subject as string}:${subItem.Attribute}`;
        if (!acc[key]) {
          acc[key] = {
            [subject]: subItem.Value,
          };
        } else {
          acc[key][subject] = acc[key][subject]
            ? acc[key][subject].concat(subItem.Value)
            : subItem.Value;
        }
        return acc;
      },
      {},
    ),
  }));
};

const generateConditions = (
  conditions: ICondition[],
  conditionId: string,
  field: string,
  value?: string | Array<string> | boolean,
): ICondition[] => {
  const generetedConditions = conditions.map(subItem => {
    if (subItem.Id === conditionId) {
      const newFields: {
        Operator?: string;
        ApplyTo?: string;
        IgnoreCase?: boolean;
      } = {};
      if (field === "Format") {
        if (value === "Numeric") {
          newFields.IgnoreCase = false;
        }
      }
      if ((field === "Operator" && value === "Like") || value === "NotLike") {
        newFields.IgnoreCase = false;
      }

      return {
        ...subItem,
        [field]: value,
        ...newFields,
      };
    } else {
      return subItem;
    }
  });

  return Array.from(new Set(generetedConditions));
};

const generateStatementsFromEntity = (statements: IStatementEntity[]) => {
  return statements.map(stateItem => ({
    ...stateItem,
    Condition: Object.keys(stateItem.Condition).reduce(
      (acc: Array<ICondition>, key: string, index) => {
        const conditionsOfCondition = Object.keys(stateItem.Condition[key]);
        conditionsOfCondition.forEach((sub, subIndex) => {
          const Format = key.includes("Numeric") ? "Numeric" : "String";
          acc.push({
            Subject: sub.split(":").length > 1 ? sub.split(":")[0] : "-",
            Operator: OperatorOptions(Format).find(format =>
              key.includes(format.value),
            )?.value,
            Format,
            ApplyTo: key.split(":").length > 1 ? key.split(":")[0] : "-",
            Value: stateItem.Condition[key][sub],
            Id: `${index}_${subIndex}`,
            IfExists: key.includes("IfExists"),
            IgnoreCase: key.includes("IgnoreCase"),
            Attribute: sub.split(":").length > 1 ? sub.split(":")[1] : "-",
          });
        });
        return acc;
      },
      [],
    ),
  }));
};

export const handleRequestError = (error: AxiosError | { message: string }) => {
  if (axios.isAxiosError(error)) {
    if (error.response?.status === 422) {
      return {
        error: (
          error.response.data as {
            detail: Array<{
              msg: string;
            }>;
          }
        ).detail[0].msg,
        data: null,
      };
    } else {
      return {
        error: (error.response?.data as { detail: string }).detail,
        data: null,
      };
    }
  } else {
    return {
      data: null,
      error: (error as { message: string }).message,
    };
  }
};

export {
  getPath,
  generateStatementsToEntity,
  generateConditions,
  generateStatementsFromEntity,
  isDevelopment,
  getHeaders,
  getCondition,
  getTenantHeaders,
};
