import { memo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { AppDispatch } from "../../store";
import { loadPolicies } from "../../store/Policies/actions";
import {
  arePoliciesLoaded,
  getPolicyById,
} from "../../store/Policies/selectors";
import { ApplicationState } from "../../store/types";
import AddPolicy from "../AddPolicy";
import { useMsal } from "@azure/msal-react";
import { Space, Spin } from "antd";

const EditPolicy = () => {
  const { tenantId, id } = useParams();
  const navigate = useNavigate();
  const msalInstance = useMsal();
  const dispatch = useDispatch<AppDispatch>();
  const isLoaded = useSelector(arePoliciesLoaded);
  const policy = useSelector((state: ApplicationState) =>
    getPolicyById(state, id),
  );

  useEffect(() => {
    if (!policy && isLoaded) {
      navigate("/404");
    }
  }, [policy, navigate, isLoaded]);

  useEffect(() => {
    if (!isLoaded) {
      dispatch(loadPolicies({ tenantId, msalInstance }));
    }
  }, [dispatch, isLoaded, msalInstance]);

  return isLoaded && policy ? (
    <AddPolicy policy={policy} />
  ) : (
    <div className="spinner-wrapper">
      <Space size="middle">
        <Spin size="large" />
      </Space>
    </div>
  );
};

export default memo(EditPolicy);
